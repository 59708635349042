import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import OurAchievementsTwo from "../../components/OurAchievements";
import WorkInquiry from "../../components/WorkInquiry";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import WhatWeOfferThree from "../../components/WhatWeOffer";
import Testimonial from "../../components/Testimonial";
import OurTeam from "../../components/OurTeam";

import shape2 from "../../images/shape2.png";

class AboutUsPage extends React.Component {

    render() {

        return (
            <Fragment>
                <BreadCrumb
                    title="About Us"
                />
                <Grid className="contentSection">
                    <Grid className="container" alignItems="center" container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Grid className="thumb">
                                <img src={shape2} alt="shape2"/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid className="textContent">
                                <strong>WHO WE ARE</strong>
                                <h2>The Largest Business Expert</h2>
                                <p>We are a web development company that specializes in creating customized and innovative digital solutions for businesses of all sizes. Our team consists of experienced developers, designers, and project managers who work together to create high-quality websites, web applications, and e-commerce platforms that help our clients succeed in their respective industries.</p>
                                <p>Our company was founded with a passion for technology and a desire to help businesses and individuals thrive in the digital age. We believe that every business deserves an app or a website that not only looks great but also functions seamlessly and efficiently. With our expertise in web development,
                                     we strive to deliver exceptional solutions that meet our clients' unique needs and exceed their expectations.</p>
                                <p>At our core, we value collaboration, transparency, and continuous improvement. We work closely with our clients to ensure that we understand their goals and objectives, and we communicate regularly throughout the development process to ensure that everyone is on the same page. We also place a strong emphasis on staying up-to-date with the latest trends and technologies in web development
                                     so that we can continue to provide cutting-edge solutions that keep our clients ahead of the curve.</p>
                                     <p>
                                     Overall, we are a dedicated and talented team that is committed to delivering outstanding web development services to our clients. Whether you need a simple website or a complex web application, we have the skills and expertise to help you achieve your goals and succeed in the digital landscape.
                                     </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <WhatWeOfferThree/>
                {/* <OurTeam/> */}
                <OurAchievementsTwo/>
                <Testimonial version='two'/>
                <WorkInquiry/>
                <Footer/>
            </Fragment>
        );
    }
}

export default AboutUsPage;