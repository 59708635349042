import React from 'react';
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import Hidden from "@material-ui/core/Hidden";

// images
import thumb from '../../images/tstthumb.jpg';

import './style.scss';

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
};

const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    dots: false,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
};


const testimonials = [
    {
        id: 1,
        thumb: thumb,
        name: 'Rita',
        profession: 'Topupcleaning.com',
        body: 'Printz Work worked closely with me, throughout the developing process, to ensure that the website met all of my requirements and was delivered on time and within budget. They were always available to answer any questions I had, and they provided regular updates on the progress of the project.'
    },
    {
        id: 2,
        thumb: thumb,
        name: 'Yasmin & Esther',
        profession: 'afriqexplore.com',
        body: 'Overall, I was thoroughly impressed with the professionalism, expertise, and level of service provided by this web developing business. I would not hesitate to work with them again in the future and would recommend them to anyone looking for a top-quality web development service.'
    },
    {
        id: 3,
        thumb: thumb,
        name: 'Justice',
        profession: '2kjustice.com',
        body: 'I recently had the pleasure of contracting Printz Work, and I cannot recommend them highly enough. From the moment I first contacted them, they were responsive, professional, and incredibly helpful.'
    },
    {
        id: 4,
        thumb: thumb,
        name: 'Yeroh',
        profession: 'yeroh.com',
        body: `What impressed me most was Printz Works' commitment to delivering a high-quality product. They went above and beyond to ensure that the mobile app was not only visually stunning, but also user-friendly and optimized for our customers. Even after the project was completed, they continued to offer ongoing support and maintenance, which has been invaluable.`
    },
];

class TestimonialCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        return (
            <Grid className="testimonialCarousel">
                <Grid container className="container">
                    <Hidden smDown><Grid item md={1}></Grid></Hidden>
                    <Grid item xs={12} md={10}>
                        <Slider
                            className="tstCarousel"
                            {...settings}
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                        >
                            {testimonials.map(item => {
                                return (
                                    <Grid key={item.id} className="tstItem">
                                        <p>{item.body}</p>
                                        <Grid className="tstAuthor">
                                            <h4>{item.name}</h4>
                                            <strong>{item.profession}</strong>
                                        </Grid>
                                    </Grid>

                                )
                            })}
                        </Slider>
                        <Grid className="arrowSliderWrapper">
                            <Slider
                                className="arrowSlider"
                                {...settings2}
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                            >
                                {testimonials.map(item => {
                                    return (
                                        <Grid key={item.id}>
                                            <Grid className="avatar">
                                                <img src={item.thumb} alt="user thumb"/>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Slider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default TestimonialCarousel;