import React from 'react';
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";

// images
import thumb from '../../images/tstthumb.jpg';
import cornerShape from '../../images/icon/corner-shape.png';

import './style.scss';

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

const testimonials = [
    {
        id: 1,
        thumb: thumb,
        name: 'Rita',
        profession: 'Topupcleaning.com',
        body: 'Printz Work worked closely with me, throughout the developing process, to ensure that the website met all of my requirements and was delivered on time and within budget. They were always available to answer any questions I had, and they provided regular updates on the progress of the project.'
    },
    {
        id: 2,
        thumb: thumb,
        name: 'Yasmin & Esther',
        profession: 'afriqexplore.com',
        body: 'Overall, I was thoroughly impressed with the professionalism, expertise, and level of service provided by this web developing business. I would not hesitate to work with them again in the future and would recommend them to anyone looking for a top-quality web development service.'
    },
    {
        id: 3,
        thumb: thumb,
        name: 'Justice',
        profession: '2kjustice.com',
        body: 'I recently had the pleasure of contracting Printz Work, and I cannot recommend them highly enough. From the moment I first contacted them, they were responsive, professional, and incredibly helpful.'
    },
    {
        id: 4,
        thumb: thumb,
        name: 'Yeroh',
        profession: 'yeroh.com',
        body: `What impressed me most was Printz Works' commitment to delivering a high-quality product. They went above and beyond to ensure that the mobile app was not only visually stunning, but also user-friendly and optimized for our customers. Even after the project was completed, they continued to offer ongoing support and maintenance, which has been invaluable.`
    },
];

const TestimonialCarouselTwo = () => {
    return (
        <Grid className="testimonialCarouselTwo">
            <Grid container className="container" spacing={4}>
                <Grid item xs={12}>
                    <Slider {...settings} >
                        {testimonials.map(item => {
                            return (
                                <Grid key={item.id} className="s2Tst">
                                    <p>{item.body} <img className="cornerShape" src={cornerShape} alt="cornerShape"/></p>
                                    <Grid className="tstMeta">
                                        <Grid className="thumb">
                                            <img src={item.thumb} alt="user thumb"/>
                                        </Grid>
                                        <Grid className="content">
                                            <h4>{item.name}</h4>
                                            <p>{item.profession}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Slider>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default TestimonialCarouselTwo;