import React from 'react';
import SectionTitle from "../SectionTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

import icon1 from '../../images/icon/store.png';
import icon2 from '../../images/icon/employee.png';
import icon3 from '../../images/icon/coins.png';
import icon4 from '../../images/icon/icon4.png';
import icon5 from '../../images/icon/icon5.png';
import icon6 from '../../images/icon/icon6.png';

import './style.scss';

const weOffer = [
    {
        id: 1,
        thumb: icon1,
        title: 'Mobile and App Development',
        body: 'We work closely with our clients to understand their visions and goals, and we use the latest technologies and dev methodologies to create custom solutions that meet their needs .'
    },
    {
        id: 2,
        thumb: icon2,
        title: 'Search Engine Optimization Services',
        body: 'Search engine optimization (SEO) services are critical to the success of any online business. By improving a websites ranking in search engine results, businesses can attract more organic traffic and generate leads and sales'
    },
    {
        id: 3,
        thumb: icon3,
        title: 'Web Analytics Integration Services',
        body: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below.'
    },
    {
        id: 4,
        thumb: icon4,
        title: 'UI/UX Design',
        body: 'It uses a dictionary of over 200 Latin words, combined with a handful of model sentence.'
    },
    {
        id: 5,
        thumb: icon5,
        title: 'Cloud Computing & Infrastructures',
        body: 'The generated Lorem Ipsum is therefore always free from repetition, injected humour.'
    },
    {
        id: 6,
        thumb: icon6,
        title: 'I.T Education',
        body: 'If you are going to use a passage of Lorem Ipsum,   hidden in the middle of text.'
    },
];

const WhatWeOffer = () => {
    return(
        <Grid className="whatWeOffer">
            <Grid className="container" container spacing={4}>
                <Grid item xs={12}>
                    <SectionTitle
                        subtitle="WHAT WE OFFER"
                        title="Explore Solutions"
                        body="Transforming Ideas into Innovative Mobile and Website Solutions."
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {weOffer.map(item => {
                            return(
                                <Grid key={item.id} item xs={12} md={4} sm={6}>
                                    <Grid className="wwItem">
                                        <Grid className="icon">
                                            <img src={item.thumb} alt="icon"/>
                                        </Grid>
                                        <div className="content">
                                            <h2>{item.title}</h2>
                                            <p>{item.body}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <div className="viewAll pt-50">
                        <Button className="cBtn"><Link to="/">View All Services</Link></Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default WhatWeOffer;