import React from 'react';
import SectionTitle from "../SectionTitle";
import Grid from "@material-ui/core/Grid";
import ExpansionPanelCard from "../ExpansionPanelCard";

import './style.scss';

const faq1 = [
    {
        id: 1,
        title: `What is your company's experience in app development?`,
        body: ` Our company has been in the mobile and website development industry for 5 years. We have developed apps for various industries such as healthcare, finance, e-commerce, and more.`
    },
    {
        id: 2,
        title: 'What platforms do you develop apps for ?',
        body: 'We develop apps for both iOS and Android platforms.'

    },
    {
        id: 3,
        title: 'How long does it take to develop an app?',
        body: 'The development time can vary depending on the complexity of the app and the features required. Typically, it can take anywhere from 2 weeks to 3 months to develop an app.'
    },
    {
        id: 4,
        title: 'What is your process for app development?',
        body: `Our app development process includes the following steps:  
        1) Requirement gathering and analysis, 2) Design and wireframing, 3) Development and testing, 4) Deployment and launch, and 5) Maintenance and support.`
    },
    {
        id: 5,
        title: 'Do you offer app marketing and promotion services?',
        body: 'Yes, we offer app marketing and promotion services to help increase visibility and downloads of the app. This includes app store optimization, social media marketing, and paid advertising.'
    },
   
];

const faq2 = [
    {
        id: 6,
        title: 'Do you sign a non-disclosure agreement (NDA)?',
        body: `Yes, we sign a non-disclosure agreement (NDA) to protect the confidentiality of our clients' project and ideas.`
    },
    {
        id: 7,
        title: 'Can you provide a cost estimate for app development?',
        body: 'Yes, we can provide a cost estimate for app development based on the project requirements. Please contact us for more information.'
    },
    {
        id: 8,
        title: 'Do you provide ongoing maintenance and support for the app?',
        body: 'Yes, we provide ongoing maintenance and support for the app after it is launched. This includes bug fixes, updates, and feature enhancements.'
    },
    {
        id: 9,
        title: 'How do you ensure the security of the app and user data?',
        body: 'We follow industry-standard security protocols and best practices to ensure the security of the app and user data. This includes encryption, data backups, and secure server hosting.'
    },
    {
        id: 10,
        title: 'Can you provide references or samples of your previous work?',
        body: 'Yes, we can provide references and samples of our previous work upon request. Please contact us for more information.'
    },
   
];

const FaqLists = () => {

    return (
        <Grid className="faqLists">
            <SectionTitle
                subtitle="FREQUENTLY ASKED QUESTIONS"
                title="Search our questions below to find some frequently asked questions"
            />
            <Grid className="container" container spacing={4}>
                <Grid item xs={12} md={6}>
                    <ExpansionPanelCard faqlists={faq1}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ExpansionPanelCard faqlists={faq2}/>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default FaqLists;